interface Teaching {
  period: string,
  course: string,
  position: string,
}

const teachings: Array<Teaching> = [
  {
    period: "2023",
    course: "ID506 미디어 인터랙션 디자인",
    position: "교육조교"
  },
  {
    period: "2022",
    course: "ID506 미디어 인터랙션 디자인",
    position: "교육조교"
  },
  {
    period: "2022",
    course: "ID503 디자인프로젝트1",
    position: "교육조교"
  },
  {
    period: "2020",
    course: "KAIST 산업디자인학과",
    position: "학술상담조교"
  },
  {
    period: "2017",
    course: "CS101 프로그래밍기초",
    position: "시간제 조교"
  },
];

export default teachings;
