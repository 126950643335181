import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { BODY_MAX_WIDTH, PAGE_MARGIN } from '../constants';


const Header = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;

  padding: ${PAGE_MARGIN}px;

  background-color: #FFFFFF80;
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);

  display: flex;
  flex-direction: row;
  gap: 20px;
`;

const Logo = styled.div`
  flex: 1 0 39px;
  align-self: center;

  margin: -${PAGE_MARGIN}px 0;

  font-size: 11px;
  font-weight: bold;
  line-height: 1.25;
`;

const Menu = styled.div`
  @media print {
    display: none;
  }

  flex: 0 1 ${BODY_MAX_WIDTH}px;
  align-self: center;

  margin: -2px 0;

  font-size: 17px;
  font-weight: 400;
  white-space: nowrap;

  & > a {
    color: #33333399;

    transition: color 0.3s;

    &:hover {
      color: #333333CC;
    }

    &:not(:last-child) {
      margin-right: clamp(13px, calc(8px + 1vw), 26px);
    }
  }
`;

const PageName = styled.div`
  @media not print {
    display: none;
  }

  flex: 0 1 ${BODY_MAX_WIDTH}px;
  align-self: center;

  margin: -2px 0;

  font-size: 16px;
  font-weight: 400;
  color: #AAAAAA;
  white-space: nowrap;
`;

const Visit = styled.div`
  flex: 1 0 39px;

  font-size: 14px;
  text-align: right;
  white-space: nowrap;

  & > div {
    margin-left: -90px;
    & > a {
      font-weight: bold;
    }
  }
`;


type Props = {
  pageName?: string
};


const NavBar = (props: Props) => {
  return (
    <Header>
      <Logo>
        <Link to="/">
          HAN<br />
          SEUNG<br />
          HYEON
        </Link>
      </Logo>
      <Menu>
        <Link to="/">홈</Link>
        <Link to="/cv">경력</Link>
        <Link to="/projects">프로젝트</Link>
      </Menu>
      <PageName>
        { props.pageName }
      </PageName>
      <Visit>
        <div>
          Visit <a href="https://han.sh">https://han.sh</a>
        </div>
      </Visit>
    </Header>
  );
}

export default NavBar;
