interface Leadership {
  period: string,
  title: string,
}

const leaderships: Array<Leadership> = [
  {
    period: "2023 - 2024",
    title: "금융 투자를 위한 메타버스 아바타의 활용 방안 연구 프로젝트 PM",
  },
  {
    period: "2022 - 2023",
    title: "차세대 착용형 전술장비 디자인 아이디어 발굴 프로젝트 PM",
  },
  {
    period: "2022 - 2024",
    title: "Wonder Lab 랩장",
  },
  {
    period: "2021 - 2023",
    title: "OTL Plus 졸업플래너 프로젝트 PM",
  },
  {
    period: "2019",
    title: "ClueMetic CTO",
  },
  {
    period: "2018",
    title: "Guideline 프로젝트 PM",
  },
  {
    period: "2018",
    title: "SPARCS 디자인팀 설립 멤버 및 초대 디자인팀장",
  },
  {
    period: "2018 - 2020",
    title: "OTL Plus 모바일 & 리뉴얼 프로젝트 PM",
  },
  {
    period: "2017 - 2018",
    title: "OTL Plus 모의시간표 프로젝트 PM",
  },
];

export default leaderships;
